<style lang="scss" scoped></style>

<template>
  <svg viewBox="0 0 105 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3108 9.79193C18.3108 6.89878 17.6966 4.00564 15.7222 1.80318C15.3822 1.45922 14.9737 0.907222 14.4307 0.693633C13.4079 0.149955 11.9848 0.011261 10.8879 0.011261H1.35634C1.15342 0.011261 0.807916 0.768528 0.607742 0.976568V15.5089C0.607742 16.0637 0.813401 19.09 0.813401 19.6392V44.6429C0.813401 44.7788 0.947764 46.088 0.947764 46.5014C0.947764 47.0561 0.8134 48.0852 1.35634 48.4985H2.58206C3.7212 48.5351 4.86125 48.4654 5.98776 48.2905C6.32778 48.2905 6.53618 47.9465 6.73636 47.7357C6.73636 47.5277 7.07638 47.3918 7.07638 47.3918C6.80217 40.3655 7.21348 34.2353 7.07638 27.8304L7.28204 27.6945C7.75642 27.6945 10.345 27.4865 10.8907 27.4865C11.8688 27.4817 12.8376 27.2936 13.7479 26.9317C15.6674 26.0995 17.4937 23.8333 17.8337 21.9748C17.8337 21.8361 18.0393 20.5268 18.0393 20.3909C18.0393 20.1829 18.2422 18.1718 18.2422 17.9776C18.3794 16.1275 18.3108 11.6615 18.3108 9.79193ZM11.9848 19.5033C11.5762 21.5004 9.46476 22.2577 7.49044 22.2577C6.94201 22.2577 6.87895 22.0497 6.87895 21.3618V21.1565C6.87895 18.9374 7.08186 8.55201 7.08186 6.41613C7.28752 5.65886 7.96756 5.86136 8.37614 5.86136C10.0214 5.86136 11.7791 6.54928 11.9848 8.3412C12.1191 10.7517 12.1191 12.1969 12.1191 14.3993C12.1136 15.8528 12.1136 17.9859 11.9848 19.5033Z"
      fill="currentColor"
    />
    <path
      d="M37.6728 47.3586C37.6728 47.0507 37.8264 45.1561 37.8264 44.8482C37.8264 43.2061 38.0787 35.7749 38.0787 34.2354V30.7486C38.0787 30.6488 38.2322 29.9165 38.2322 29.8277C38.2322 27.9831 38.4845 19.728 38.4845 17.9361V17.5256C38.4845 17.2676 38.4845 16.8571 38.0787 16.8571H34.5331C34.3796 16.8571 34.1273 16.7045 33.9847 16.7045C33.8832 16.7045 33.7324 16.3966 33.7324 16.3966V12.7046C33.7324 12.7046 33.8832 12.3995 33.9847 12.3995H34.6949C35.608 12.3995 43.5629 12.552 44.5254 12.552H46.8562C47.0097 12.552 47.4046 12.7046 47.5664 12.7046L47.6678 12.8599V16.9597H43.2996L43.1461 17.115L42.9953 17.5256V35.0564C42.9953 36.1826 42.5894 45.6665 42.5894 46.7955V47.7691C42.5894 47.8717 42.4386 48.4348 42.4386 48.6013L42.3372 48.7538H39.0466C38.7423 48.7538 37.9333 48.7538 37.6756 48.3433L37.6728 47.3586Z"
      fill="currentColor"
    />
    <path
      d="M51.1969 45.8718C51.1969 44.0272 51.3477 28.1883 51.3477 26.291C51.3477 22.4076 51.1969 18.5491 51.1969 14.6546C51.1969 13.9889 50.994 12.7129 51.855 12.5021C54.2379 12.5021 56.6181 12.4494 59.0009 12.6547C59.8236 12.7573 61.4853 13.0652 62.1928 13.5784C64.5757 15.4729 64.8279 18.3966 64.8279 21.1649C64.8279 24.4936 64.4221 26.9567 61.9405 29.9303V30.3409C62.903 31.0066 63.7146 31.5198 64.0684 33.1147C64.5236 35.1119 64.8279 40.6042 64.8279 44.3406C64.8279 45.7275 65.3764 46.9536 65.689 47.7747C65.4888 48.4931 65.4367 48.3905 64.5236 48.3905C63.2567 48.3905 62.1928 48.4404 60.8245 48.3378C60.3693 47.93 59.9113 47.5195 59.9113 44.6984C59.9113 43.4696 59.9634 41.7776 59.9634 40.0328C59.9634 36.5987 60.1142 32.0884 56.6181 32.0884C56.5298 32.0813 56.4412 32.0937 56.3581 32.1246C56.2751 32.1555 56.1997 32.2043 56.1371 32.2676C56.0745 32.3309 56.0263 32.4072 55.9957 32.4912C55.9651 32.5752 55.9529 32.6649 55.9599 32.7541C55.9599 33.5225 55.8091 37.4198 55.8091 38.2409C55.8091 38.7956 55.9599 43.4696 55.9599 44.0327C55.9599 45.5694 56.1629 46.9536 56.1629 48.1325C55.8091 49.0562 52.8175 48.4404 51.6027 48.4404L51.3477 48.1852C51.2545 48.0243 51.1969 46.385 51.1969 45.8718ZM59.5576 26.8541C59.6591 24.5463 59.8126 22.2911 59.8126 19.986C59.8126 19.0124 59.5082 17.2676 58.3428 17.0124H56.4672L56.1327 17.423V27.7778L56.385 28.033C57.586 28.0275 58.7542 28.2383 59.5631 26.8541H59.5576Z"
      fill="currentColor"
    />
    <path
      d="M74.8147 48.948C74.0551 48.948 73.0433 48.6928 72.4839 48.077C71.217 46.948 70.2546 45.6665 69.7994 43.9162C69.251 41.1978 69.0892 38.7401 68.9384 35.872V35.4614C68.9384 33.7167 69.0892 25.6197 69.0892 23.9277C69.0892 19.6725 70.0023 12.3939 76.3366 12.3939C80.5923 12.3939 82.3143 14.8543 82.9176 17.8778C83.0655 18.2896 83.1505 18.7217 83.1699 19.1594V37.8747L82.9176 41.9245C82.6132 42.4793 82.6132 43.2061 82.3089 43.7719C80.8912 46.6928 79.4708 48.948 75.9252 48.948H74.8147ZM76.1857 44.335C77.5047 44.335 77.9105 42.9481 77.9105 41.9245C77.9105 41.3087 78.0613 38.5959 78.0613 38.0411C78.0613 36.4517 78.2122 28.8124 78.2122 27.1232V18.857C78.2122 17.986 77.5047 17.0124 76.4901 17.0124H76.3393C76.0076 17.0763 75.6845 17.1797 75.3768 17.3203C75.2233 17.3203 74.6666 17.986 74.5131 18.1525C74.4389 18.2969 74.388 18.4523 74.3622 18.6129C74.3622 19.4839 74.2087 23.0705 74.2087 23.9443V42.8066C74.3595 43.7719 75.3741 44.335 76.183 44.335H76.1857Z"
      fill="currentColor"
    />
    <path
      d="M89.6139 46.4376C89.6139 46.0992 89.2821 36.5682 89.1477 32.8928C89.1477 32.2104 89.4795 20.6379 89.4219 16.4188C89.4219 13.8308 89.1477 2.93777 89.1477 0.557792C89.1549 0.477151 89.1778 0.398755 89.2152 0.327131C89.2525 0.255506 89.3035 0.192071 89.3652 0.14049C89.4269 0.0889085 89.4981 0.0502025 89.5746 0.026607C89.6512 0.00301143 89.7316 -0.0050062 89.8113 0.00301706L94.7964 0.147258L95.3284 0.702033V2.32475C95.397 3.07647 95.1968 6.76294 95.1968 7.43145L95.7946 41.1978C95.8236 41.3311 95.8887 41.4536 95.9826 41.5516C96.0766 41.6496 96.1957 41.7192 96.3265 41.7526L103.977 41.4752C104.177 41.4752 104.578 42.03 104.578 42.2241L104.975 46.2407C105.109 47.3308 104.701 47.7386 103.714 47.8052C99.0909 48.2406 94.444 48.3546 89.8058 48.1463L89.6139 46.4376Z"
      fill="currentColor"
    />
    <path
      d="M32.6081 45.0008C32.5039 44.2823 31.3413 36.9011 30.9355 32.2881C30.9355 32.1855 30.7819 31.6224 30.7819 31.456L29.9593 24.6905C29.7564 23.3036 29.4108 21.7668 29.3012 20.3827C28.8953 17.8224 28.7939 15.2066 28.0343 12.6935C26.2108 12.5409 24.3873 12.6436 22.7146 12.6436C22.5117 12.9515 21.6507 17.4618 21.6507 18.7461C21.4971 19.8223 21.0419 20.9652 20.9405 22.0276C20.6882 24.6933 20.1178 27.2036 19.8765 29.8194L17.8501 46.2241C17.798 46.3766 17.5458 47.611 17.5458 47.7608C17.5458 47.9106 17.4443 48.4265 17.6966 48.4265C18.1024 48.4265 20.4387 48.5819 20.839 48.5819H21.2449C22.6159 48.5819 21.8536 42.532 22.8654 42.532H27.2226C27.4777 42.7373 27.9329 46.274 27.9329 47.3503C27.9329 47.8135 27.9329 48.7372 28.4401 48.7372H32.8494L33.0003 48.5846C33.0112 47.3586 32.7069 46.2324 32.6081 45.0008ZM26.677 38.7457L26.5755 38.8983H23.285C23.179 38.8583 23.0929 38.7775 23.0456 38.6736C22.9983 38.5696 22.9937 38.4509 23.0327 38.3435C23.0327 37.4198 23.285 36.4462 23.4385 35.4725C23.8416 32.2937 24.6039 29.37 24.6533 25.8888C24.6533 25.7862 24.8041 25.4783 25.0591 25.4783C25.5143 25.4783 26.2245 34.0384 26.2739 33.9857C26.2711 38.4378 26.932 38.08 26.677 38.7457Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
